<template>
<div>
	<v-card class="page-menu mb-3" rounded="0"  flat>
            <v-sheet
                elevation="2"
				rounded="5"
                height="40"
				max-width="100%"
                class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-2"
            >
                <v-icon class="white--text mb-2">mdi-database</v-icon>
                プラント管理
            </v-sheet>
            <v-card class="ma-5">
                <v-system-bar color="gray" height="50">
                    <v-card-subtitle class="text-h6 font-weight-bold">
                        会員情報
                    </v-card-subtitle>
                    <v-spacer />
                    <v-btn class="mx-2 btn-close" @click="clickBack"
                        >戻る</v-btn
                    >
                    <v-btn class="mx-2 btn-edit" @click="clickEdit">編集</v-btn>
                </v-system-bar>
            </v-card>
	</v-card>
	<v-card class="mx-5">
        <v-simple-table class="plant-tbl">
            <colgroup>
            <col style="width:200px">
            </colgroup>
            <tr>
                <th>入会日</th>
                <td>{{memberInfo.nyukai_bi}}</td>
            </tr>
            <tr>
                <th>会員番号</th>
                <td>{{memberInfo.member_cd}}</td>
            </tr>
            <tr>
                <th>会員（会社）名称</th>
                <td>{{memberInfo.member_name}}</td>
            </tr>
            <tr>
                <th>会員（会社）名称カナ</th>
                <td>{{memberInfo.member_name_kana}}</td>
            </tr>
            <tr>
                <th>法人番号</th>
                <td>{{memberInfo.member_no}}</td>
            </tr>
            <tr>
                <th>住所</th>
                <td>
                    <div>
                        〒 <span class="pr-2">{{memberInfo.post}}</span>
                    </div>
                    <div>
                        {{memberInfo.address3}}
                    </div>
                </td>
            </tr>
            <tr>
                <th>連絡先</th>
                <td>
                    <div class="d-flex align-center">
                        <span v-if="memberInfo.tel"><span class="pr-2">（TEL）</span>{{memberInfo.tel}}</span>
                        <span v-if="memberInfo.fax"><span class="px-2">（FAX）</span>{{memberInfo.tel}}</span>
                    </div>
                    <div class="d-flex align-center">
                        <span v-if="memberInfo.tantou_name"><span class="pr-2">（担当者）</span>{{memberInfo.tantou_name}}</span>
                    </div>
                </td>

            </tr>
            <tr>
                <th>ホームページ</th>
                <td>
                    <template v-if="memberInfo.homepage">
                    <a :href="memberInfo.homepage" target="_blank" rel="noopener noreferrer">{{ memberInfo.homepage }}</a>
                    </template>
                </td>
            </tr>
        </v-simple-table>
    </v-card>
</div>
</template>
<style>


.plant-tbl th {
    border: 1px solid #ccc;
    padding-left: 5px;
    background-color: #555;
    color: white;
    font-weight: normal;
    padding: 5px 25px 5px 25px;
    font-size: 0.8em;
    text-align: left;
    /*text-align-last: justify;*/
}
</style>
<script>
import { CONSTANT } from '../constant';
export default {
    components: {  },
    data(){
        return {
            memberInfo:[],
        }
    },
    mounted(){
        this.getMember();
    },
    methods:{
        async getMember(){
            this.memberInfo = [];
            //this.$store.commit('showOverlay');
            this.setOverlay(true);
            const res = await this.axios.post('/member/get-member');
            this.setOverlay(false);
            //this.$store.commit('hideOverlay');
            if(res.data.resultCode == CONSTANT.RETURN_NORMAL){
                this.memberInfo = res.data.resultData.memberInfo;
            }
        },
        clickBack(){
            this.$router.push('/');
        },
        clickEdit(){
            this.$router.push("/member-edit");
        }
    }

}
</script>